import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/store';

Vue.use(Router);

function isUser({ next }) {
    if (!store.getters.isLoggedIn) {
        return next({
            name: 'index'
        });
    }
    return next();
}

function isAdmin({ next }) {
    if (!store.getters.isAdmin) {
        return next({
            name: 'index'
        });
    }
    return next();
}

function isCustomer({ next }) {
    if (!store.getters.isCustomer) {
        return next({
            name: 'index'
        });
    }
    return next();
}

function isDeveloper({ next }) {
    if (!store.getters.isDeveloper) {
        return next({
            name: 'index'
        });
    }
    return next();
}

function isCustomerOrAdmin({ next }) {
    if (!store.getters.isCustomer && !store.getters.isAdmin) {
        return next({
            name: 'index'
        });
    }
    return next();
}

function isAbcFinance({ next }) {
    if (!store.getters.isAbcFinance) {
        return next({
            name: 'index'
        });
    }
    return next();
}

function isAbcFinanceOrAdmin({ next }) {
    if (!store.getters.isAbcFinance && !store.getters.isAdmin) {
        return next({
            name: 'index'
        });
    }
    return next();
}

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    linkActiveClass: "active-path",
    linkExactActiveClass: "active",
    routes: [
        {
            path: '*',
            name: 'not_found',
            component: () => import('@/views/Page404'),
            meta: {

            }
        },
        {
            path: '/',
            name: 'index',
            component: () => import('@/views/PageIndex'),
            meta: {

            }
        },
        {
            path: '/jobs/:pagen?',
            name: 'jobs',
            component: () => import('@/views/PageJobs'),
            meta: {
                access: isUser
            }
        },
        {
            path: '/profile',
            name: 'profile',
            component: () => import('@/views/PageProfile'),
            meta: {
                access: isUser
            }
        },
        {
            path: '/customer',
            name: 'customer',
            component: () => import('@/views/PageCustomer'),
            meta: {
                access: isCustomer
            }
        },
                                                      
        {
            path: '/customer/task-performers',
            name: 'customer-task-performers',
            component: () => import('@/views/PageCustomerTaskPerformers'),
            meta: {
                access: isCustomer
            }
        },
                  
                                              
        {
            path: '/customer-payments',
            name: 'customer-payments',
            component: () => import('@/views/PageCustomerPayments'),
            meta: {
                access: isAbcFinance
            }
        },
        {
            path: '/customer-payments/:id',
            name: 'customer-payment',
            component: () => import('@/views/PageCustomerPayment'),
            meta: {
                access: isAbcFinance
            }
        },
                  
                                                    
        {
            path: '/customer/help/:scenario?/:chapter?',
            name: 'customer-help',
            component: () => import('@/views/PageCustomerHelp'),
            meta: {
                access: isCustomer
            }
        },
                  
        {
            path: '/user/:userId',
            name: 'user',
            component: () => import('@/views/PageUser'),
            meta: {
                access: isAdmin
            }
        },
                                       
        {
            path: '/admin/users/balances',
            name: 'admin-users-balances',
            component: () => import('@/views/PageAdminUserBalancesFromTagme'),
            meta: {
                access: isAdmin
            }
        },
                  
        {
            path: '/admin/users/:pagen?',
            name: 'admin-users',
            component: () => import('@/views/PageAdminUsers'),
            meta: {
                access: isAdmin
            }
        },
        {
            path: '/admin/teams/:pagen?',
            name: 'admin-teams',
            component: () => import('@/views/PageAdminTeams'),
            meta: {
                access: isAbcFinanceOrAdmin
            }
        },
        {
            path: '/admin/team/:teamUuid',
            name: 'admin-team-view',
            component: () => import('@/views/PageAdminTeamView'),
            meta: {
                access: isAbcFinanceOrAdmin
            }
        },
                                               
        {
            path: '/admin/settings/:pagen?',
            name: 'admin-settings',
            component: () => import('@/views/PageAdminSettings'),
            meta: {
                access: isAdmin
            }
        },
                  
                                                   
        {
            path: '/report/constructor',
            name: 'report-constructor',
            component: () => import('@/views/PageReportConstructor'),
            meta: {
                access: isUser
            }
        },
                  
        {
            path: '/admin/moderation',
            name: 'admin-moderation',
            component: () => import('@/views/PageAdminModerationQueue'),
            meta: {
                access: isUser
            }
        },
        {
            path: '/projects/add',
            name: 'project-add',
            component: () => import('@/views/PageProjectAdd'),
            meta: {
                access: isUser
            }
        },
        {
            path: '/project/edit/:uuid',
            name: 'project-edit',
            component: () => import('@/views/PageProjectEdit'),
            meta: {
                access: isUser
            }
        },
        {
            path: '/custom-service-queue/:form_id/:uuid/:action',
            name: 'custom-service-queue',
            component: () => import('@/views/PageCustomServiceQueue'),
            meta: {
                access: isUser
            }
        },
        {
            path: '/moderation-multi/:form_id/:uuid/:action',
            name: 'moderation-multi',
            component: () => import('@/views/PageCustomServiceQueue'),
            meta: {
                access: isUser
            }
        },
        {
            path: '/additional-custom-service/:form_id/:uuid/',
            name: 'additional-custom-service',
            component: () => import('@/views/PageAdditionalCustomService'),
            meta: {
                access: isUser
            }
        },
        {
            path: '/project/:uuid/:action?/:action_params?',
            name: 'project',
            component: () => import('@/views/PageProject'),
            meta: {
                access: isUser
            }
        },
        {
            path: '/projects/:pagen?',
            name: 'projects',
            component: () => import('@/views/PageProjects'),
            meta: {
                access: isUser
            }
        },
                                             
        {
            path: '/ui-kit',
            name: 'ui-kit',
            component: () => import('@/views/PageUIKit'),
            meta: {
                access: isAdmin
            }
        },
                  
                                                
        {
            path: '/devtools',
            name: 'devtools',
            component: () => import('@/views/PageDevTools'),
            meta: {
                access: isDeveloper
            }
        },
                  
                                               
        {
            path: '/payments',
            name: 'payments',
            component: () => import('@/views/PagePayments'),
            meta: {
                access: isAbcFinanceOrAdmin
            }
        },
                  
        {
            path: '/messenger/:pagen?',
            name: 'messenger',
            component: () => import('@/views/PageMessenger'),
            meta: {
                access: isUser
            }
        },
        {
            path: '/message/:id',
            name: 'message',
            component: () => import('@/views/PageMessengerMessage'),
            meta: {
                access: isUser
            }
        },
                                             
        {
            path: '/user-manual',
            name: 'user-manual',
            component: () => import('@/views/PageUserManual'),
            meta: {
                access: isUser
            }
        },
                  
        {
            path: '/reset-password/:hash',
            name: 'reset-password',
            component: () => import('@/views/PageResetPassword'),
            meta: {

            }
        },
                                          
        {
            path: '/offer',
            name: 'offer',
            component: () => import('@/views/PageOffer'),
            meta: {

            }
        },
                  
        {
            path: '/error/:text',
            name: 'error',
            component: () => import('@/views/PageError'),
            meta: {

            }
        },
        {
            path: '/skills',
            name: 'skills',
            component: () => import('@/views/PageSkills'),
            meta: {
                access: isCustomerOrAdmin
            }
        },
        {
            path: '/view-answers-failed/:id/:type/:object/:answer_id?',
            name: 'view-answers-failed',
            component: () => import('@/views/PageViewFailedAnswers'),
            meta: {
                access: isUser
            }
        },
        {
            path: '/view-answers/:task_id/:user_id/:answer_id?',
            name: 'view-answers',
            component: () => import('@/views/PageViewUserAnswers'),
            meta: {
                access: isUser
            }
        },
        {
            path: '/view-answers-pool/:uuid/:answer_id?',
            name: 'view-answers-pool',
            component: () => import('@/views/PageViewPoolAnswers'),
            meta: {
                access: isUser
            }
        },
        {
            path: '/pool/:uuid',
            name: 'pool',
            component: () => import('@/views/PagePool'),
            meta: {
                access: isUser
            }
        },
        {
            path: '/pool/edit/:uuid',
            name: 'pool-edit',
            component: () => import('@/views/PagePoolEdit'),
            meta: {
                access: isUser
            }
        },
                                                  
        {
            path: '/charts',
            name: 'charts',
            component: () => import('@/views/PageChart'),
            meta: {
                access: isUser
            }
        },
                  
                                                                                                                      
        {
            path: '/logs',
            name: 'logs',
            component: () => import('@/views/PageLogs'),
            meta: {
                access: isUser
            }
        },
                  
                                                       
        {
            path: '/payment-info',
            name: 'payment-info',
            component: () => import('@/views/PagePaymentInfo'),
            meta: {
                access: isUser
            }
        },
                  
                                                   
        {
            path: '/admin/dashboard',
            name: 'admin-dashboard',
            component: () => import('@/views/PageAdminDashboard'),
            meta: {
                access: isAdmin
            }
        },
                  
                                                     
        {
            path: '/analytics/dashboard/list/:pagen?',
            name: 'analytics-dashboard-list',
            component: () => import('@/views/PageAnalyticsDashboardList'),
            meta: {
                access: isAdmin
            }
        },
        {
            path: '/analytics/dashboard/:dashboard_uuid',
            name: 'analytics-dashboard-view',
            component: () => import('@/views/PageAnalyticsDashboardView'),
            meta: {
                access: isAdmin
            }
        },
                  
                                              
        {
            path: '/openid/login',
            name: 'openid-login',
            component: () => import('@/views/PageOpenidLogin'),
            meta: {

            }
        },
        {
            path: '/openid/result/:providerName',
            name: 'openid-result',
            component: () => import('@/views/PageOpenidResult'),
            meta: {

            }
        },
                  
                                          
        {
            path: '/docs/api/:scope?',
            name: 'docs-api',
            component: () => import('@/views/PageDocsApi'),
            meta: {
                access: isUser
            }
        },
                  
                                       
        {
            path: '/tagme',
            name: 'tagme',
            component: () => import('@/views/PageTagme'),
            meta: {
                access: isUser
            }
        },
                  
/* REDIRECTS */
        {
            path: '/admin/custom-forms-v2/add',
            redirect: to => {
                return '/projects/add'
            }
        },
        {
            path: '/admin/custom-form-v2/:uuid',
            redirect: to => {
                return '/project/edit/:uuid'
            }
        },
        {
            path: '/skills/:pagen?',
            redirect: to => {
                return '/skills'
            }
        },
        {
            path: '/dev-logs',
            redirect: to => {
                return '/logs'
            }
        },
        {
            path: '/notification-logs',
            redirect: to => {
                return '/logs'
            }
        },
        {
            path: '/balance',
            redirect: to => ({
                name: 'profile',
                query: {tab: 'finance'}
            })
        },
        {
            path: '/docs/public',
            redirect: to => {
                return '/docs/api'
            }
        },
        {
            path: '/docs/customer',
            redirect: to => {
                return '/docs/api/customer'
            }
        }
    ]
});

router.beforeEach((to, from, next) => {
    if( to.name === 'index' && store.getters.isLoggedIn ){
        return next({ name: 'jobs' });
    }
    if (!to.meta.access) {
        return next();
    }
    return to.meta.access({ ...to, from, next });
});

export default router;
