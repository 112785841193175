import Vue from 'vue';
import App from '@/App';
import router from '@/router';
import store from '@/store/store';
import Toast, { POSITION } from 'vue-toastification';
import { libAuth } from '@/lib/libAuth';
import { events } from '@/lib/events';
import Vuelidate from 'vuelidate';
import VueTippy from 'vue-tippy';
import VueI18n from 'vue-i18n';
import { defaultLang } from '@/i18n';
                                      
import * as Sentry from '@sentry/vue';
          

import '@/styles/tailwind.css';

                                      
if( process.env.VUE_APP_SENTRY_DSN && document.location.hostname !== 'localhost' && document.location.hostname !== '127.0.0.1' ){
    Sentry.init({
        Vue,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router)
            }),
        ],
        ignoreErrors: [
            'Non-Error promise rejection captured',
            'Navigation cancelled from',
            'Avoided redundant navigation to current location',
            /^Loading chunk \d* failed/,
            /^Loading CSS chunk \d* failed/
        ],
        tracesSampleRate: 0.02,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        environment: process.env.NODE_ENV === 'production' ? 'prod' : 'dev',
    });
}
          

Vue.use(Toast, {
    position: POSITION.BOTTOM_RIGHT,
    timeout: 5000
});
Vue.use(Vuelidate);
Vue.use(VueTippy, {
    interactive: true,
    delay: 300
});
Vue.use(VueI18n);

Vue.config.productionTip = false;

Vue.prototype.$evbus = new Vue(); // Global event bus

Vue.prototype.$openPopup = function (name, data = {}) {
    this.$evbus.$emit(events.EV_APP_OPEN_POPUP, {
        name: `popup-${name}`,
        ...data
    });
}

Vue.prototype.$closePopup = function (name) {
    this.$evbus.$emit(events.EV_APP_CLOSE_POPUP, {
        name: `popup-${name}`
    });
}

const i18n = new VueI18n({
    locale: process.env.VUE_APP_DEFAULT_LANG,
    messages: defaultLang,
    silentTranslationWarn: true,
    pluralizationRules: {
        'ru': function(choice, choicesLength) {
            let resp,
                count = choice % 100;
            if( choicesLength > 3 ){
                return choice;
            }
            if (count >= 5 && count <= 20) {
                resp = 0;
            } else {
                count = count % 10;
                if (count == 1) {
                    resp = 1;
                } else if (count >= 2 && count <= 4) {
                    resp = 2;
                } else {
                    resp = 0;
                }
            }
            return resp;
        }
    }
});

async function initVue() {
    new Vue({
        router,
        store,
        i18n,
        render: h => h(App)
    }).$mount('#app');
}

// has something in storage?
store.dispatch('loadUserInfoFromStorage').then((isLoggedIn) => {
    if (isLoggedIn) {//refresh token exists
        if (store.getters.accessToken && !libAuth.isAccessTokenExpired()){ //access token exists and not expired
            initVue();
        }else{
            libAuth.refreshTokens().finally(() => {// set new tokens or refresh token was invalid
                initVue();
            });
        }
    } else { //missing refresh token
        let routeMatch = router.matcher.match(document.location.pathname);
        if (routeMatch.name && routeMatch.name !== 'index') {// if route exist remember params
            store.commit('setOriginalRoute', {
                name: routeMatch.name,
                params: routeMatch.params
            });
        }
        initVue().then( () => {
            // open login popup
        });
    }
});


