<template>
    <div
        v-if="isVisible"
        class="modal modal--visible"
        :class="{'i18n--loading': i18nLoading }"
        >
        <div class="modal__overlay" @click.self="onPopupClose()">
            <div class="modal__container">
                <div class="modal__header">
                    {{ $t('lbls.requisites') }}
                    <div class="modal__close">
                        <button class="btn btn--action" :title="$t('btns.close')" @click="onPopupClose()">
                            <icon-close-thin />
                        </button>
                    </div>
                </div>
                <div class="wrapper">
                    <div class="modal__main scroll scroll--thin">

                        <el-preloader v-if="requisites.loading" />

                        <template v-else>
                            <div class="grid gap-4 grid-cols-12" v-if="emptyRequisitesNames.length">
                                <div class="col-span-12">
                                    <h4>
                                        {{ $t('title') }}
                                    </h4>
                                </div>
                                <div class="col-span-12 md:col-span-8" v-if="emptyRequisitesNames.includes('juridical_name')">
                                    <div class="control control--text" :class="{'control--invalid': $v.requisites.juridical_name.$error}">
                                        <label class="control__label block mb-1" :class="{'control__label--required': $v.requisites.juridical_name.$params.required }">
                                            {{ $t('lbls.legal_entity') }}
                                        </label>
                                        <input
                                            type="text"
                                            class="control__input"
                                            v-model.trim="requisites.juridical_name"
                                        >
                                        <div class="control__msg control__msg--invalid text--small">
                                            {{ $t('errors.required_field') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-span-12 md:col-span-8" v-if="emptyRequisitesNames.includes('inn')">
                                    <div class="control control--text" :class="{'control--invalid': $v.requisites.inn.$error}">
                                        <label class="control__label block mb-1" :class="{'control__label--required': $v.requisites.inn.$params.required }">
                                            {{ $t('lbls.inn') }}
                                        </label>
                                        <input
                                            type="text"
                                            class="control__input"
                                            v-model.trim="requisites.inn"
                                            v-numeric-only
                                        >
                                        <div class="control__msg control__msg--invalid text--small">
                                            {{ $t('errors.required_field') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-span-12 md:col-span-8" v-if="emptyRequisitesNames.includes('sector')">
                                    <div class="control control--text" :class="{'control--invalid': $v.requisites.sector.$error}">
                                        <label class="control__label block mb-1" :class="{'control__label--required': $v.requisites.sector.$params.required }">
                                            {{ $t('lbls.sector') }}
                                        </label>
                                        <input
                                            type="text"
                                            class="control__input"
                                            v-model.trim="requisites.sector"
                                        >
                                        <div class="control__msg control__msg--invalid text--small">
                                            {{ $t('errors.required_field') }}
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="col-span-12 md:col-span-8"
                                    v-for="key in ['country', 'city', 'street', 'house']"
                                    :key="`dadata_${key}`"
                                    >
                                    <template v-if="emptyRequisitesNames.includes(key)">
                                        <label class="control__label block mb-1" :class="{'control__label--required': $v.requisites[key].$params.required }">
                                            {{ $t(`lbls.${key}`) }}
                                        </label>
                                        <multiselect
                                            :class="{'control--invalid': $v.requisites[key].$error}"
                                            :options="dadata[key].results"
                                            v-model="requisites[key]"
                                            :loading="dadata[key].loading"
                                            @search-change="searchChange($event, key)"
                                            :placeholder="$t('lbls.search_placeholder')"
                                            >
                                            <template slot="noResult">
                                                {{ $t('lbls.no_search_rezults') }}
                                            </template>
                                            <template slot="noOptions">
                                                {{ $t('lbls.empty_list') }}
                                            </template>
                                        </multiselect>
                                        <div class="control__msg control__msg--invalid text--small" :class="{'block': $v.requisites[key].$error}">
                                            {{ $t('errors.required_field') }}
                                        </div>
                                    </template>
                                </div>
                                <div class="col-span-12 md:col-span-8" v-if="emptyRequisitesNames.includes('house')">
                                    <div class="control control--text">
                                        <label class="control__label block mb-1">
                                            {{ $t('lbls.building') }}
                                        </label>
                                        <input
                                            type="text"
                                            class="control__input"
                                            v-model.trim="requisites.corpus"
                                        >
                                    </div>
                                </div>
                                <div class="col-span-12 md:col-span-8" v-if="emptyRequisitesNames.includes('house')">
                                    <div class="control control--text">
                                        <label class="control__label block mb-1">
                                            {{ $t('lbls.flat') }}
                                        </label>
                                        <input
                                            type="text"
                                            class="control__input"
                                            v-model.trim="requisites.flat"
                                        >
                                    </div>
                                </div>
                                <div class="col-span-12 md:col-span-8" v-if="emptyRequisitesNames.includes('source')">
                                    <div class="control control--text" :class="{'control--invalid': $v.requisites.source.$error}">
                                        <label class="control__label block mb-1" :class="{'control__label--required': $v.requisites.source.$params.required }">
                                            {{ $t('lbls.source') }}
                                        </label>
                                        <input
                                            type="text"
                                            class="control__input"
                                            v-model.trim="requisites.source"
                                        >
                                        <div class="control__msg control__msg--invalid text--small">
                                            {{ $t('errors.required_field') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-span-12 md:col-span-8" v-if="emptyRequisitesNames.includes('phone')">
                                    <div class="control control--text" :class="{'control--invalid': $v.requisites.phone.$error}">
                                        <label class="control__label block mb-1" :class="{'control__label--required': $v.requisites.phone.$params.required }">
                                            {{ $t('lbls.phone') }}
                                        </label>
                                        <input
                                            type="tel"
                                            class="control__input"
                                            v-model.trim="requisites.phone"
                                            placeholder="+7(XXX)XXX-XX-XX"
                                            v-mask="/\+7\(\d{3}\)\d{3}-\d{2}-\d{2}/"
                                            autocomplete="tel"
                                        >
                                        <div class="control__msg control__msg--invalid text--small">
                                            {{ $t('errors.required_field') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-span-12 md:col-span-8" v-if="emptyRequisitesNames.includes('email')">
                                    <div class="control control--text" :class="{'control--invalid': $v.requisites.email.$error}">
                                        <label class="control__label block mb-1" :class="{'control__label--required': $v.requisites.email.$params.required }">
                                            {{ $t('lbls.email') }}
                                        </label>
                                        <input
                                            type="email"
                                            class="control__input"
                                            v-model.trim="requisites.email"
                                        >
                                        <div class="control__msg control__msg--invalid text--small">
                                            {{ $t('errors.required_field') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-span-12 md:col-span-8" v-if="emptyRequisitesNames.includes('juridical_person')">
                                    <div class="control control--text" :class="{'control--invalid': $v.requisites.juridical_person.$error}">
                                        <label class="control__label block mb-1" :class="{'control__label--required': $v.requisites.juridical_person.$params.required }">
                                            {{ $t('juridical_person') }}
                                        </label>
                                        <input
                                            type="text"
                                            class="control__input"
                                            v-model.trim="requisites.juridical_person"
                                        >
                                        <div class="control__msg control__msg--invalid text--small">
                                            {{ $t('errors.required_field') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-span-12 md:col-span-8" v-if="emptyRequisitesNames.includes('juridical_based')">
                                    <div class="control control--text" :class="{'control--invalid': $v.requisites.juridical_based.$error}">
                                        <label class="control__label block mb-1" :class="{'control__label--required': $v.requisites.juridical_based.$params.required }">
                                            {{ $t('juridical_based') }}
                                        </label>
                                        <input
                                            type="text"
                                            class="control__input"
                                            v-model.trim="requisites.juridical_based"
                                        >
                                        <div class="control__msg control__msg--invalid text--small">
                                            {{ $t('errors.required_field') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-span-12 md:col-span-8" v-if="emptyRequisitesNames.includes('juridical_post')">
                                    <div class="control control--text" :class="{'control--invalid': $v.requisites.juridical_post.$error}">
                                        <label class="control__label block mb-1" :class="{'control__label--required': $v.requisites.juridical_post.$params.required }">
                                            {{ $t('juridical_post') }}
                                        </label>
                                        <input
                                            type="text"
                                            class="control__input"
                                            v-model.trim="requisites.juridical_post"
                                        >
                                        <div class="control__msg control__msg--invalid text--small">
                                            {{ $t('errors.required_field') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-span-12 md:col-span-8" v-if="emptyRequisitesNames.includes('juridical_fio')">
                                    <div class="control control--text" :class="{'control--invalid': $v.requisites.juridical_fio.$error}">
                                        <label class="control__label block mb-1" :class="{'control__label--required': $v.requisites.juridical_fio.$params.required }">
                                            {{ $t('juridical_fio') }}
                                        </label>
                                        <input
                                            type="text"
                                            class="control__input"
                                            v-model.trim="requisites.juridical_fio"
                                        >
                                        <div class="control__msg control__msg--invalid text--small">
                                            {{ $t('errors.required_field') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-span-12">
                                    <div class="alert alert--warning inline-block mb-0">
                                        {{ $t('warning') }} <a :href="`mailto:${$store.state.project.helpEmail}`" target="_blank">{{ $store.state.project.helpEmail }}</a>
                                    </div>
                                </div>
                            </div>
                            <div class="block" v-else>
                                <div class="alert alert--warning inline-block mb-0">
                                    {{ $t('lbls.no_data') }}
                                </div>
                            </div>
                        </template>

                    </div>
                    <div class="modal__footer" v-if="!requisites.loading">
                        <button
                            v-if="emptyRequisitesNames.length"
                            class="btn btn--primary btn--sm"
                            :class="{'btn--loading': requisites.updating}"
                            :disabled="requisites.updating"
                            :title="$t('btns.save')"
                            @click="updateRequisites()"
                            >
                            {{ $t('btns.save') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import mixinI18n from '@/mixins/mixinI18n.js';
    import { defaultLang } from '@/i18n/components/popups/ElPopupMissedRequisites';
    import { request } from '@/lib/ajax';
    import { requiredIf, minLength, maxLength, email } from 'vuelidate/lib/validators';
    import { getDigits } from '@/lib/libUtils';
    import { isPhone } from '@/lib/libValidators';
    import mask from 'vue-r-mask';
    import mixinPopup from '@/mixins/mixinPopup';
    import iconYokassa from '@/components/icons/iconYokassa';
    import iconSber from '@/components/icons/iconSber';
    import ElPreloader from '@/components/ElPreloader';
    import numericOnly from '@/directives/numericOnly';
    import Multiselect from 'vue-multiselect';

    export default {
        name: 'ElPopupMissedRequisites',
        directives: {
            mask: mask,
            numericOnly: numericOnly
        },
        mixins: [
            mixinPopup,
            mixinI18n
        ],
        components: {
            iconYokassa,
            Multiselect,
            iconSber,
            ElPreloader
        },
        data() {
            return {
                requisites: {
                    loading: false,
                    updating: false,
                    data: [],
                    country: '',
                    sector: '',
                    city: '',
                    street: '',
                    house: '',
                    corpus: '',
                    flat: '',
                    source: '',
                    juridical_name: '',
                    inn: '',
                    phone: '',
                    email: '',
                    juridical_person: '',
                    juridical_based: '',
                    juridical_post: '',
                    juridical_fio: ''
                },
                dadata: {
                    timeout: null,
                    country: {
                        loading: false,
                        results: []
                    },
                    city: {
                        loading: false,
                        results: []
                    },
                    street: {
                        loading: false,
                        results: []
                    },
                    house: {
                        loading: false,
                        results: []
                    }
                }
            }
        },
        i18n: {
            messages: defaultLang
        },
        computed: {
            emptyRequisitesNames(){
                let resp = [];
                if( this.requisites?.data?.length ){
                    this.requisites.data.map( item => {
                        resp.push(item.name);
                    })
                }
                return resp;
            }
        },
        validations(){
            let validators = {
                requisites: {
                    inn: {
                        required: requiredIf( instance => {
                            return this.emptyRequisitesNames.includes('inn');
                        }),
                        minLength: minLength(10),
                        maxLength: maxLength(12)
                    },
                    juridical_name: {
                        required: requiredIf( instance => {
                            return this.emptyRequisitesNames.includes('juridical_name');
                        })
                    },
                    country: {
                        required: requiredIf( instance => {
                            return this.emptyRequisitesNames.includes('country');
                        })
                    },
                    sector: {
                        required: requiredIf( instance => {
                            return this.emptyRequisitesNames.includes('sector');
                        })
                    },
                    city: {
                        required: requiredIf( instance => {
                            return this.emptyRequisitesNames.includes('city');
                        })
                    },
                    street: {
                        required: requiredIf( instance => {
                            return this.emptyRequisitesNames.includes('street');
                        })
                    },
                    house: {
                        required: requiredIf( instance => {
                            return this.emptyRequisitesNames.includes('house');
                        })
                    },
                    source: {
                        required: requiredIf( instance => {
                            return this.emptyRequisitesNames.includes('source');
                        })
                    },
                    phone: {
                        required: requiredIf( instance => {
                            return this.emptyRequisitesNames.includes('phone');
                        }),
                        isPhone
                    },
                    email: {
                        required: requiredIf( instance => {
                            return this.emptyRequisitesNames.includes('email');
                        }),
                        email
                    },
                    juridical_person: {
                        required: requiredIf( instance => {
                            return this.emptyRequisitesNames.includes('juridical_person');
                        })
                    },
                    juridical_based: {
                        required: requiredIf( instance => {
                            return this.emptyRequisitesNames.includes('juridical_based');
                        })
                    },
                    juridical_post: {
                        required: requiredIf( instance => {
                            return this.emptyRequisitesNames.includes('juridical_post');
                        })
                    },
                    juridical_fio: {
                        required: requiredIf( instance => {
                            return this.emptyRequisitesNames.includes('juridical_fio');
                        })
                    }
                }
            }
            return validators;
        },
        methods: {
            clearData(key){
                this.requisites.inn = '';
                this.requisites.juridical_name = '';
                this.requisites.country = '';
                this.requisites.sector = '';
                this.requisites.city = '';
                this.requisites.street = '';
                this.requisites.house = '';
                this.requisites.corpus = '';
                this.requisites.flat = '';
                this.requisites.source = '';
                this.requisites.phone = '';
                this.requisites.email = '';
                this.requisites.juridical_person = '';
                this.requisites.juridical_based = '';
                this.requisites.juridical_post = '';
                this.requisites.juridical_fio = '';
            },
            onPopupShow(){
                this.requisites.loading = true;
                request({
                    url: 'api/v1/customer/check-team-requisites',
                    method: 'GET'
                }).then( resp => {
                    this.requisites.data = resp.data;
                    this.requisites.loading = false;
                });
                this.showPopup();
            },
            requisitesLabelByKey(key){
                let field = this.requisites.data.find( item => item.name == key);
                return field ? field.title : key;
            },
            updateRequisites(){
                this.$v.$touch();
                if( this.$v.$invalid ){
                    this.$toast.error( this.$t('errors.fill_in') );
                    return;
                }
                this.requisites.updating = true;
                let requestData = {};
                this.emptyRequisitesNames.map(key => {
                    if(key === 'phone'){
                        requestData[key] = getDigits(this.requisites[key]);
                    }else{
                        requestData[key] = this.requisites[key];
                    }
                });
                request({
                    url: 'api/v1/customer/set-team-requisites',
                    data: {
                        data: requestData
                    }
                }).then( resp => {
                    if( resp.data == true ){
                        this.requisites.data = [];
                        this.clearData();
                        let user = {... this.$store.getters.user};
                        user.is_authorized = true;
                        this.$store.dispatch('changeUserInfo', {
                            user: user
                        });
                        this.closePopup();
                    }
                }).finally(() => {
                    this.$v.$reset();
                    this.requisites.updating = false;
                });
            },
            complementSearchQuery(query, searchKey){
                query = query.trim();
                if( !query.length ){ return ''; }
                ['country', 'city', 'street'].reverse().map( key => {
                    if( this.requisites[key] && searchKey !== key ){
                        query = `${this.requisites[key]} ${query}`
                    }
                });
                return query;
            },
            searchChange(query, key){
                let dadataKey = key;
                if( ['city', 'street'].includes(key) ){
                    dadataKey = `${key}_with_type`;
                }

                query = this.complementSearchQuery(query, key);
                if( !query.length ){ return; }

                if( this.dadata.timeout ){
                    clearTimeout( this.dadata.timeout );
                }

                this.dadata.timeout = setTimeout(() => {
                    this.dadata[key].loading = true;
                    request({
                        url: `api/v1/default/get-fias-addr`,
                        method: 'GET',
                        data: {
                            address: query
                        }
                    }).then( resp => {
                        this.dadata[key].results = resp[dadataKey] ? [ resp[dadataKey] ] : [];
                    }).finally( () => {
                        this.dadata[key].loading = false;
                    });
                }, 900);
            },
            async loadI18nFile(lang){
                let loadModule = () => import(`@/i18n/components/popups/ElPopupMissedRequisites/${lang}.js`);
                await loadModule().then(module => {
                    this.$i18n.setLocaleMessage(lang, module.default);
                });
            }
        }
    }
</script>
